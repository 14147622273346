import { useCallback, useEffect, useRef } from 'react'

import { KeyboardKey } from 'src/types'

/**
 * This helps cover all of the keyboard navigation actions required to implement a menu where the items do NOT receive focus.
 * This helps menu-like components support up and down arrow keyboard navigation, escape key for closing, and space/enter for selecting a menu item.
 */
export const useMenuKeyboardShortcuts = (args: {
  activeIndex: number
  count: number
  isEnabled: boolean
  onEscape?: () => void
  onSelect: (e: KeyboardEvent) => void
  setActiveIndex: (activeIndex: number) => void
}) => {
  const { activeIndex, count, isEnabled, onEscape, onSelect, setActiveIndex } =
    args
  const menuItemRefs = useRef<Map<number, HTMLElement | null>>(
    new Map<number, HTMLElement | null>()
  )

  const keyDownListener = useCallback(
    (e: KeyboardEvent) => {
      if (!isEnabled || e.altKey) {
        return false
      }
      switch (e.key) {
        case KeyboardKey.Enter:
          onSelect(e)
          e.preventDefault()
          e.stopPropagation()
          break
        case KeyboardKey.ArrowDown:
        case KeyboardKey.ArrowUp:
          {
            // Move to next or previous menu item
            const direction = e.key === KeyboardKey.ArrowDown ? 1 : -1
            let newIndex = (activeIndex + direction) % count
            if (newIndex < 0) {
              newIndex = count - 1
            }
            setActiveIndex(newIndex)
            const menuItem = menuItemRefs.current.get(newIndex)
            menuItem?.scrollIntoView({ block: 'nearest', behavior: 'instant' })
            e.preventDefault()
            e.stopPropagation()
          }
          break
        case KeyboardKey.Escape:
          {
            if (!onEscape) {
              return false
            }
            onEscape()
            e.preventDefault()
            e.stopPropagation()
          }
          break
        default:
          return false
      }
      return true
    },
    [activeIndex, count, isEnabled, onEscape, onSelect, setActiveIndex]
  )

  useEffect(() => {
    window.addEventListener('keydown', keyDownListener, {
      capture: true, // remove once not competing with antd modal escape key handlers
    })
    return () =>
      window.removeEventListener('keydown', keyDownListener, {
        capture: true, // remove once not competing with antd modal escape key handlers
      })
  }, [
    activeIndex,
    count,
    isEnabled,
    keyDownListener,
    menuItemRefs,
    onEscape,
    onSelect,
    setActiveIndex,
  ])

  return {
    activeIndex,
    keyDownListener,
    setActiveIndex,
    menuItemRefs,
  }
}
