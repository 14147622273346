import { useCallback, useState } from 'react'

import FormControl from 'govwell-ui/components/FormControl/FormControl'
import {
  FormControlProps,
  getCaptionId,
} from 'govwell-ui/components/FormControl/util'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { TextSize, getFontSize } from 'src/components/Typography/Text'

const StyledTextArea = styled.textarea<{
  $resize: React.CSSProperties['resize']
}>`
  background-color: ${({ theme }) => theme.colorWhite};
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.colorBorder};
  padding: 5px 12px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${getFontSize(TextSize.Base)}px;
  resize: ${({ $resize }) => $resize ?? 'vertical'};
`

type Props = FormControlProps & {
  value: string
  onValueChange: (value: string) => void
  placeholder?: string
  resize?: React.CSSProperties['resize']
  rows?: number
}
const TextArea = ({
  caption,
  id: propId,
  isRequired,
  label,
  onValueChange,
  placeholder,
  resize,
  rows = 3,
  value,
  width,
}: Props) => {
  const [id] = useState(propId ?? uuid())

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onValueChange(e.target.value ?? '')
    },
    [onValueChange]
  )

  return (
    <FormControl
      caption={caption}
      id={id}
      isRequired={isRequired}
      label={label}
      width={width}
    >
      <StyledTextArea
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        $resize={resize}
        rows={rows}
        {...(caption ? { ['aria-describedby']: getCaptionId(id) } : {})}
      />
    </FormControl>
  )
}

export default TextArea
