export const CodeBookItemFragment = gql`
  fragment CodeBookItemFragment on CodeBookItem {
    id
    formattedName
    section
    title
    text
  }
`

export const CodeBookItemOptionFragment = gql`
  fragment CodeBookItemOptionFragment on CodeBookItem {
    ...CodeBookItemFragment
    codeBook {
      id
      name
    }
  }
`
